<h2 mat-dialog-title>Materiales Asociados</h2>
<mat-dialog-content>
  <table mat-table [dataSource]="dataSource" matTableResponsive matTableExporter
                                #exporter="matTableExporter" class="mat-elevation-z8 w-100" matSort>


                                <ng-container matColumnDef="nomGenerico">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Material</th>
                                    <td mat-cell *matCellDef="let element"> {{element.nomGenerico}} </td>
                                </ng-container>



                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                            </table> 
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>
