import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';


// START COMPONENTS LAYOUT ADMIN
import { SkeletonComponent } from './layout/skeleton/skeleton.component';
import { LeftNavComponent } from './layout/left-nav/left-nav.component';
import { NavigationComponent } from './layout/navigation/navigation.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { HeaderSearchComponent } from './layout/header/header-search/header-search.component';
import { HeaderTasksComponent } from './layout/header/header-tasks/header-tasks.component';
import { HeaderAlertsOptionalComponent } from './layout/header/header-alerts-optional/header-alerts-optional.component';
import { HeaderMessagesOptionalComponent } from './layout/header/header-messages-optional/header-messages-optional.component';
import { HeaderUserComponent } from './layout/header/header-user/header-user.component';
// END COMPONENTS LAYOUT ADMIN

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Page404Component } from './modules/server/page404/page404.component';
import { Page401Component } from './modules/server/page401/page401.component';
import { NotificationService } from '@shared/components/notification/notification.service';
import { ViewMaterialsModalComponent } from './modules-katia/proveedor/view-materials-modal/view-materials-modal.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};


@NgModule({
  declarations: [
    AppComponent,
    SkeletonComponent,
    LeftNavComponent,
    NavigationComponent,
    FooterComponent,
    HeaderComponent,
    HeaderSearchComponent,
    HeaderTasksComponent,
    HeaderAlertsOptionalComponent,
    HeaderMessagesOptionalComponent,
    HeaderUserComponent,
    Page404Component,
    Page401Component,
    ViewMaterialsModalComponent,
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    // Core
    CoreModule,
    SharedModule,
    AppRoutingModule,
    PerfectScrollbarModule,
    MatDialogModule,
    MatTableModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG, 
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {// evita el signo $ en las rutas y toma la ruta path local
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    //Provider para tener las notificaciones genericas a toda la aplicacion
    NotificationService
  ],


  bootstrap: [AppComponent]
})
export class AppModule { }
