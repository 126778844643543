import { environment as ENV } from "environments/environment";

export const API_ROUTES = {
    AUTH: {
        LOGIN: `${ENV.uri}authenticate`,
        REFRESH_TOKEN: `${ENV.uri}refreshtoken`,
    },
    USERS: {
        LIST: `${ENV.uri}users`,
        LIST_BY_COMPANY: `${ENV.uri}getAllUsersByCompany`,
        UPDATE: `${ENV.uri}updateUser`,
        NEW: `${ENV.uri}createUser`,
        UPDATE_STATUS: `${ENV.uri}updateStatusUser`,
        FIND_USER_BY_ID: `${ENV.uri}findByUserId`,
        RESET_PASSWORD: `${ENV.uri}resetPassword`,
        FORGOT_PASSWORD: `${ENV.uri}forgotPassword`,
        PUT_NET_PASSWORD: `${ENV.uri}putNewPasswd`,
        FIND_USER_BY_DOCUMENT: `${ENV.uri}findUserByDocument`,
        ADD_COMPANY_TO_USER: `${ENV.uri}users`,
    },
    COMPANY: {
        LIST: `${ENV.uri}companies`,
        NEW: `${ENV.uri}createCompany`,
        EDIT: `${ENV.uri}updateCompany`,
        MODULES_BY_COMPANY: `${ENV.uri}getModulesByCompany`,
        ASSIGN_MODULE: `${ENV.uri}createModuleToCompany`,
        FIND_COMPANY_BY_ID: `${ENV.uri}findByCompanyId`,
        UPDATE_STATUS: `${ENV.uri}updateCompanyStatus`,

    },
    MODULE: {
        LIST: `${ENV.uri}modules`,
        NEW: `${ENV.uri}createModule`,
        EDIT: `${ENV.uri}updateModule`,
        PARENT: `${ENV.uri}parentModules`,
        SUBMODULE_LIST: `${ENV.uri}modulesByParentId`,
        FIND_MODULE_BY_ID:  `${ENV.uri}findModuleById`,
        FIND_SUBMODULE_BY_ROLE_ID:  `${ENV.uri}getSubModulesByEspecificRole`,
        ADD_SUBMODULES_TO_COMPANY:  `${ENV.uri}addSubmodulesToCompany`,
        ADD_SUBMODULES_TO_ROLE:  `${ENV.uri}addSubmodules`,
        DELETE_SUBMODULES_TO_ROLE:  `${ENV.uri}deleteSubmodules`,
        FIND_MOD_AND_SUBMOD_BY_USER_AND_COMPANY:  `${ENV.uri}getModAndSubmodByUserAndCompany`,
        UPDATE_STATUS_MODULE: `${ENV.uri}updateStatusModule`,
    },
    ROLE: {
        LIST: `${ENV.uri}roles`,
        NEW: `${ENV.uri}createRole`,
        EDIT: `${ENV.uri}updateRole`,
        FIND_ROLE_BY_ID: `${ENV.uri}findRoleById`,
        FIND_ROLES_BY_COMPANY_ID: `${ENV.uri}getAllRolesByCompanyId`,
        ADD_ROLE_TO_USER: `${ENV.uri}addRoleToUser`,
        FIND_ROLES_BY_USER_ID: `${ENV.uri}getAllRolesByUserId`,
    },
    DOMAINS: {
        DOC_TYPES: {
            LIST: `${ENV.uri}docTypes`,
        },
        MATERIAL_TYPES: {
            LIST_ACTIVES: `${ENV.uri}tiposMaterialActivos`,
        },
        UNIT_MEASURE: {
            LIST_ACTIVES: `${ENV.uri}unidadesMedidaActivas`,
        },
        TYPE_PRODUCTS: {
            LIST_ACTIVES: `${ENV.uri}tiposProductoActivos`,
        },
        PRESENTATIONS: {
            LIST_ACTIVES: `${ENV.uri}presentacionesActivas`,
            LIST_A: `${ENV.uri}presentacionesActivas`,
            GET_LITS_BY_ID: `${ENV.uri}obtenerPresentaciones`,

        }
    },
    KATIA: {
        MATERIALS: {
            LIST: `${ENV.uri}listarMateriales`,
            LIST_SIN_ASOCIAR: `${ENV.uri}listarMaterialesSinAsociar`,
            LIST_PROVEEDOR: `${ENV.uri}obtenerProveedorMaterialPorId`,
            NEW: `${ENV.uri}crearMaterial`,
            FIND_MATERIAL_BY_ID: `${ENV.uri}obtenerMaterialPorId`,
            EDIT: `${ENV.uri}actualizarMaterial`,
            UPDATE_STATUS: `${ENV.uri}actualizarEstadoMaterial`,
            FIND_MATERIAL_BY_PROVIDER: `${ENV.uri}listarMaterialesPorProveedorId`,
            ELIMINAR_MATERIAL_PROVEEDOR: `${ENV.uri}eliminarMaterialProveedor`,
        },
        MATERIALS_TYPE: {
            LIST: `${ENV.uri}tiposMaterial`,
            LIST_BY_ID: `${ENV.uri}tipoMaterialPorId`,
        },
        PROVIDERS: {
            LIST: `${ENV.uri}proveedores`,
            LIST_ACTIVE: `${ENV.uri}proveedoresActivos`,
            NEW: `${ENV.uri}crearProveedor`,
            EDIT: `${ENV.uri}actualizarProveedor`,
            FIND_PROVIDER_BY_ID: `${ENV.uri}proveedorPorId`,
            UPDATE_STATUS: `${ENV.uri}actualizarEstadoProveedor`,
            ASOCIAR_MATERIAL: `${ENV.uri}asociarMaterialProveedor`,
        },
        MATERIALS_HISTORICAL: {
            LIST: `${ENV.uri}listarHistoricosMateriales`,
        },
        ORDENES_COMPRA: {
            LIST: `${ENV.uri}listarOrdenesCompra`,
            LIST_BY_ID: `${ENV.uri}obtenerOrdenCompraPorId`,
            NEW: `${ENV.uri}crearOrdenCompra`,
            UPDATE_STATUS: `${ENV.uri}actualizarEstadoOrdenCompra`,
            DECLINE: `${ENV.uri}rechazarOrdenCompra`,
        },
        MATERIAL_ORDENES_COMPRA: {
            MATERIALS_BY_ORDER: `${ENV.uri}obtenerOrdenCompraMaterialPorOC`,
            ADD_MATERIAL_ORDER: `${ENV.uri}crearOrdenCompraMaterial`,
            MATERIALS_BY_ID: `${ENV.uri}obtenerOrdenCompraMaterialPorId`,
            EDIT_MATERIAL_ORDER: `${ENV.uri}actualizarOrdenCompraMaterial`,
            UPDATE_STATUS_MATERIAL_ORDER: `${ENV.uri}actualizarEstadoOrdenCompraM`,
            UPDATE_STATUS_MATERIALS_TO_CHECK: `${ENV.uri}actualizarMaterialesOCPorRevisar`,
            GET_ALL_MATERIALS_TO_OC: `${ENV.uri}obtenerOrdenesCompraMateriales`,
            GET_ALL_MATERIALS_TO_INSPECTION: `${ENV.uri}obtenerMaterialesParaInspeccion`,
            GET_ALL_MATERIALS_TO_CHECK: `${ENV.uri}obtenerMaterialesPorRevisar`,
            APPROVE_DECLINE_MATERIALS: `${ENV.uri}actualizarNotasCalidad`,
            UPDATE_STATUS_MATERIALS: `${ENV.uri}actualizarEstadoMaterialOc`

        },
        INSPECTION: {
            MEE_INSUMOS_NEW: `${ENV.uri}crearInspeccionMee`,
            MP_NEW: `${ENV.uri}crearInspeccionMp`,
            FIND_INSPECTION_MEE_INSUMOS_BY_ENTERORDERMATERIALID: `${ENV.uri}inspeccionMeePorOcmId`,
            FIND_INSPECTION_MP_BY_ENTERORDERMATERIALID: `${ENV.uri}inspeccionMpPorOcmId`,
        },
        CALIDAD: {
            LIBERACION_NEW: `${ENV.uri}crearLiberacionAgua`,
            LIBERACION_LIST: `${ENV.uri}listarLiberacionAgua`,
            LIBERACION_EDIT: `${ENV.uri}actualizarliberacionAgua`,
            UPDATE_STATUS_LIBERACION:  `${ENV.uri}actualizarEstadoLiberacionAgua`,
            LIBERACION_BYID: `${ENV.uri}obtenerLiberacionesPorId`,

        },
        BODEGA: {
            MATERIALES_NEW: `${ENV.uri}crearEntradaBodegaMaterial`,
            MATERIALES_LIST: `${ENV.uri}listarEntradasBodegaMaterial`,
            PRODUCTOS_NEW: `${ENV.uri}crearEntradaBodegaProducto`,
            PRODUCTOS_LIST: `${ENV.uri}listarEntradasBodegaProducto`,
            EXISTENCIAS_MATERIALES_LIST:`${ENV.uri}listarExistenciasBodegaMaterial`,
            EXISTENCIAS_PRODUCTO_LIST:`${ENV.uri}listarExistenciasBodegaProducto`,
        },
        COMERCIAL: {
            PROVEEDOR_NEW: `${ENV.uri}crearProveedorInternacional`,
            PROVEEDOR_EDIT: `${ENV.uri}actualizarProInternacional`,
            PROVEEDOR_ESTADO: `${ENV.uri}actualizarEstadoProInternacional`,
            PROVEEDOR_LIST: `${ENV.uri}listarProveedoresInternacionales`,
            PROVEEDOR_BYID: `${ENV.uri}obtenerProveedoresPorId`,
            CLIENTES_NEW: `${ENV.uri}crearClienteInternacional`,
            CLIENTES_EDIT: `${ENV.uri}actualizarCliInternacional`,
            CLIENTES_ESTADO: `${ENV.uri}actualizarEstadoCliInternacional`,
            CLIENTES_LIST: `${ENV.uri}listarClientesInternacionales`,
            CLIENTES_BYID: `${ENV.uri}obtenerClientesPorId`,
            PROFORMA_NEW: `${ENV.uri}crearProforma`,
            PROFORMA_BY_CLIENTEID: `${ENV.uri}obtenerProformaPorClienteId`,
            PROFORMA_BYID: `${ENV.uri}obtenerProformaPorId`,
            PROFORMA_ITEM_EDIT: `${ENV.uri}actualizarItemProforma`,
            EMPAQUE_NEW: `${ENV.uri}crearListadoEmpaque`,
            EMPAQUE_BYID: `${ENV.uri}obtenerListadoEmpaquePorId`,
            EMPAQUE_BY_CLIENTEID: `${ENV.uri}obtenerListadoEmpaquePorClienteId`,
            EMPAQUE_ITEM_EDIT: `${ENV.uri}actualizarItemListado`,
            SEGUIMIENTO_NEW: `${ENV.uri}agregarPedido`,
            SEGUIMIENTO_EDIT: `${ENV.uri}actualizarPedido`,
            SEGUIMIENTO_DELETE: `${ENV.uri}actualizarEstadoHistorial`,
            SEGUIMIENTO_BYID: `${ENV.uri}obtenerPedidoPorId`,
            SEGUIMIENTO_BY_CLIENTEID: `${ENV.uri}obtenerHistorialPorClienteId`,
        },
        PRODUCTION: {
            PRODUCT_NEW: `${ENV.uri}crearProducto`,
            PRODUCT_EDIT: `${ENV.uri}actualizarProducto`,
            UPDATE_STATUS_PRODUCT: `${ENV.uri}actualizarEstadoProducto`,
            LIST: `${ENV.uri}listarProductosActivos`,
            LIST_ORDERS: `${ENV.uri}listarOrdenesProduccion`,
            GET_ORDERS_BY_ID: `${ENV.uri}ordenProduccionPorId`,
            ORDER_NEW: `${ENV.uri}crearOrdenProduccion`,
            UPDATE_ORDER: `${ENV.uri}actualizarOrdenProduccion`,
            APPROVE_ORDER:`${ENV.uri}aprobarOrdenProduccion`,
            GET_MATERIALS_PRODUCTION_ORDER: `${ENV.uri}obtenerMaterialesOrdenProd`,
            FIND_PRODUCT_BY_ID: `${ENV.uri}productoPorId`,
            INVENTORY: {
                NEW_INVENTORY: `${ENV.uri}registrarMaterialBodegaInventario`,
            }

        },
        EQUIPOS: {
            EQUIPOS_NEW: `${ENV.uri}crearEquipo`,
            EQUIPOS_LIST: `${ENV.uri}listarEquipos`,
            GET_EQUIPOS_BY_ID: `${ENV.uri}obtenerEquiposPorId`,
            UPDATE_EQUIPOS: `${ENV.uri}actualizarEquipo`,
            UPDATE_STATUS: `${ENV.uri}actualizarEstadoEquipo`,
            GET_EQUIPOS_ACTIVOS: `${ENV.uri}listarEquipos`,
            EQUIPOS_ACTIVOS: `${ENV.uri}obtenerEquiposActivos`,
            TIPOS_MANTENIMIENTO_LIST: `${ENV.uri}listarTiposDeMantenimientos`,
            MANTENIMIENTO_NEW: `${ENV.uri}registrarMantenimiento`,
            UPDATE_MANTENIMIENTO: `${ENV.uri}actualizarMantenimiento`,
            UPDATE_STATUS_MANTENIMIENTO: `${ENV.uri}actualizarEstadoMantenimiento`,
            GET_MANTENIMIENTO_BY_ID: `${ENV.uri}obtenerMantenimientoPorId`,
            MANTENIMIENTO_LIST: `${ENV.uri}listarMantenimientos`,
            MANTENIMIENTOS_ACTIVOS: `${ENV.uri}obtenerMantenimientosActivos`,
            EQUIPOS_PRODUCTO: `${ENV.uri}obtenerEquiposPorProducto`,
            EQUIPOS_PRODUCTO_NEW: `${ENV.uri}asociarEquipoProducto`,
            UPDATE_STATUS_EQUIPOS_PRODUCTO: `${ENV.uri}actualizarEstadoRelacion`,
            GET_TIPOS_EQUIPO: `${ENV.uri}obtenerTiposEquipos`,

        },
        INSTRUCTIVO: {
          PRECAUCIONES_LIST: `${ENV.uri}obtenerPrecauciones`,
          PRECAUCION_PRODUCTO_NEW: `${ENV.uri}asociarPrecaucionProducto`,
          GET_PRECAUCIONES_BY_PRODUCTO: `${ENV.uri}obtenerPrecaucionesPorProducto`,
          UPDATE_STATUS_PRECAUCIONES_PRODUCTO: `${ENV.uri}actualizarEstadoPrecaucionProducto`,
          GET_TIPO_PASOS_PROCEDIMIENTO: `${ENV.uri}obtenerTipoPasosProcedimientos`,
          PASO_PROCEDIMIENTO_NEW: `${ENV.uri}crearPasoProcedimientos`,
          UPDATE_PASO_PROCEDIMIENTO: `${ENV.uri}actualizarPasoProcedimiento`,
          GET_PASOS_PROCEDIMIENTO_BY_ID: `${ENV.uri}obtenerPasosProcedimientoPorId`,
          GET_PASOS_PROCEDIMIENTO_BY_PRODUCTO: `${ENV.uri}obtenerPasosProcedimientosPorProducto`,
          UPDATE_PASO_PROCEDIMIENTO_EA: `${ENV.uri}actualizarPasoProcedimientoEa`,
          GET_PASOS_PROCEDIMIENTO_EA_BY_ID: `${ENV.uri}obtenerPasosProcedimientoEaPorId`,
          GET_PASOS_PROCEDIMIENTO_EA_BY_PRODUCTO: `${ENV.uri}obtenerPasosProcedimientosEaPorProducto`,
          UPDATE_STATUS_PASOS_PROCEDIMIENTO: `${ENV.uri}actualizarEstadoPasoProcedimiento`,
          GUARDAR_INSTRUCTIVO: `${ENV.uri}guardarInstructivo`,
          OBTENER_INSTRUCTIVO_BY_ORDEN: `${ENV.uri}obtenerInstructivoPorOrdProd`,
          APROBAR_PASO_PROCEDIMIENTO: `${ENV.uri}aprobarPasoProcedimiento`,
          ROTULO_NEW: `${ENV.uri}crearRotulo`,
          ROTULO_UPDATE: `${ENV.uri}actualizarRotulo`,
          GET_ROTULOS_BY_ORDEN: `${ENV.uri}obtenerRotulosPorOrdProd`,
          VERIFICAR_ROTULOS: `${ENV.uri}aprobarRotulo`,
        },
        PROGRAMACION:{
          CRONOGRAMA_TITULO_NEW: `${ENV.uri}guardarCronogramaTitulo`,
          GET_CRONOGRAMA_TITULO: `${ENV.uri}listarCronogramaTitulo`,
          GET_BY_ID_CRONOGRAMA_TITULO: `${ENV.uri}obtenerCronogramaTituloPorId`,
          CRONOGRAMA_TITULO_UPDATE: `${ENV.uri}actualizarCronogramaTitulo`,
          CRONOGRAMA_TITULO_UPDATE_STATUS: `${ENV.uri}actualizarEstadoCronogramaTitulo`,
          GET_LISTADO_CRONOGRAMA_BY_TITULO: `${ENV.uri}obtenerCronogramaPorCronogramaTitulo`,
          GET_OPERACIONES_SEMISOLIDOS: `${ENV.uri}obtenerOperacionesSemisolidos`,
          RELACIONAR_ORDEN_CRONOGRAMA: `${ENV.uri}crearCronograma`,
          UPTADE_RELACION_ORDEN:`${ENV.uri}actualizarCronograma`,
          UPDATE_STATUS_RELACION: `${ENV.uri}inactivarCronograma`,
          GET_LISTADO_CRONOGRAMA: `${ENV.uri}listarCronograma`,
          GET_ORDEN_CRONOGRAMA_BY_ID: `${ENV.uri}obtenerCronogramaPorId`,
          GET_TIPOS_OPERACIONES: `${ENV.uri}obtenerTiposOperaciones`,
          PLANEACION_NEW: `${ENV.uri}guardarProgramacion`,
          GET_PLANEACION: `${ENV.uri}reporte`,
          GET_PLANEACION_BY_ID: `${ENV.uri}obtenerProgramacionPorId`,
          GET_ALL_ANIOS: `${ENV.uri}listarAnios`,
          UPTADE_PLANEACION:`${ENV.uri}actualizarProgramacion`,
        }
    },
    GEOGRAPHIC: {
        DEPARTMENT: {
            LIST: `${ENV.uri}deptosActivos`,
        },
        CITIES: {
            FIND_CITIES_BY_DEPARTMENT_ID:  `${ENV.uri}municipioPorDeptoId`,
        },
    }
}
