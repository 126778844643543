import { COMPANY_CONSTANTS, USER_CONSTANTS } from '@data/constants';
import { MATERIAL_CONSTANTS } from '@data/constants/pages/katia/material.const';
import { ORDEN_COMPRA_CONSTANTS } from '@data/constants/pages/katia/orden-compra.const';
import { PROVIDER_CONSTANTS } from '@data/constants/pages/katia/provider.const';
import { QUALITY_CONSTANTS } from '@data/constants/pages/katia/quality.const';
import { MODULE_CONSTANTS } from '@data/constants/pages/module/module-const';
import { ROLE_CONSTANTS } from '@data/constants/pages/role/role-const';
import { SUPERADMIN_CONSTANTS } from '@data/constants/pages/superadmin/superadmin.const';
import { INTERNAL_ROUTES } from '@data/constants/routes';
import { NavigationInfo } from './inavigation.metadata';
import { PRODUCTION_CONSTANTS } from '@data/constants/pages/katia/production.const';
import { BODEGA_CONSTANTS } from '@data/constants/pages/katia/bodega.const';
import { COMERCIAL_CONSTANTS} from '@data/constants/pages/katia/comercial.const'
import { EQUIPOS_CONSTANTS} from '@data/constants/pages/katia/equipos.const'
import { PROGRAMACION_CONSTANTS} from '@data/constants/pages/katia/programacion.const'

//Sidebar menu Routes and data
export const NAVIGATION_ROUTES: NavigationInfo[] = [
    {
        path: '', title: COMPANY_CONSTANTS.TITLES.ADMIN_COMPANY, icon: 'bx bx-brightness', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            //{ path: INTERNAL_ROUTES.COMPANY_NEW, title: COMPANY_CONSTANTS.TITLES.COMPANY_NEW, icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: INTERNAL_ROUTES.COMPANY_LIST, title: COMPANY_CONSTANTS.TITLES.COMPANY, icon: 'bx bx-building', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: INTERNAL_ROUTES.SUPERADMIN_USERS, title: SUPERADMIN_CONSTANTS.TITLES.CREATE_ADMIN, icon: 'bx bx-user-pin', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: INTERNAL_ROUTES.SUPERADMIN_MODULES, title: SUPERADMIN_CONSTANTS.TITLES.MODULES, icon: 'bx bx-window-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: INTERNAL_ROUTES.ROLE_LIST, title: ROLE_CONSTANTS.TITLES.ROLE, icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '', title: ORDEN_COMPRA_CONSTANTS.TITLES.ORDENES_DE_COMPRA, icon: 'bx bx-purchase-tag-alt', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: INTERNAL_ROUTES.KATIA_ORDEN_COMPRA_NEW, title: ORDEN_COMPRA_CONSTANTS.TITLES.ENTER_ORDER, icon: 'bx bx-plus', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: INTERNAL_ROUTES.KATIA_ORDEN_COMPRA_LIST, title: ORDEN_COMPRA_CONSTANTS.TITLES.ORDENES_DE_COMPRA, icon: 'bx bx-layer', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: INTERNAL_ROUTES.KATIA_ORDEN_COMPRA_LIST, title: ORDEN_COMPRA_CONSTANTS.TITLES.MANAGE_ORDER_BUTTON, icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], isButton: true },
            { path: INTERNAL_ROUTES.KATIA_ORDEN_COMPRA_CUARENTENA, title: ORDEN_COMPRA_CONSTANTS.TITLES.ENTER_MATERIAL_WAREHOUSE_BUTTON, icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], isButton: true },

        ]
    },
    {
        path: '', title: MATERIAL_CONSTANTS.TITLES.ADMIN_MATERIALS, icon: 'bx bx-hive', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: INTERNAL_ROUTES.KATIA_MATERIALS_NEW, title: MATERIAL_CONSTANTS.TITLES.MATERIALS_NEW, icon: 'bx bx-plus', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: INTERNAL_ROUTES.KATIA_MATERIALS_LIST, title: MATERIAL_CONSTANTS.TITLES.MATERIALS, icon: 'bx bx-book-content', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: INTERNAL_ROUTES.KATIA_MATERIALS_HISTORICAL, title: MATERIAL_CONSTANTS.TITLES.HISTORICAL_MATERIALS, icon: 'lni lni-keyword-research', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '', title: USER_CONSTANTS.TITLES.ADMIN_GENERAL, icon: 'lni lni-cogs', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: INTERNAL_ROUTES.PANEL_USER_LIST, title: USER_CONSTANTS.TITLES.USERS, icon: 'bx bx-group', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: INTERNAL_ROUTES.PANEL_USER_ROLES_LIST, title: USER_CONSTANTS.TITLES.PROFILES, icon: 'lni lni-network', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '', title: SUPERADMIN_CONSTANTS.TITLES.SUPERADMIN, icon: 'bx bx-user', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: INTERNAL_ROUTES.MODULE_LIST, title: MODULE_CONSTANTS.TITLES.MODULE, icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

        ]
    },
    {
        path: '', title: PROVIDER_CONSTANTS.TITLES.ADMIN_PROVIDERS, icon: 'bx bx-hive', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: INTERNAL_ROUTES.KATIA_PROVIDERS_NEW, title: PROVIDER_CONSTANTS.TITLES.PROVIDERS_NEW, icon: 'bx bx-plus', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: INTERNAL_ROUTES.KATIA_PROVIDERS_LIST, title: PROVIDER_CONSTANTS.TITLES.PROVIDERS, icon: 'bx bx-book-content', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '', title: QUALITY_CONSTANTS.TITLES.QUALITY, icon: 'lni lni-certificate', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: INTERNAL_ROUTES.KATIA_QUALITY_MATERIALS_TO_CHECK_LIST, title: QUALITY_CONSTANTS.TITLES.QUALITY_MATERIALS_TO_CHECK, icon: 'bx bx-test-tube', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: INTERNAL_ROUTES.KATIA_QUALITY_ALL_MATERIALS_LIST, title: QUALITY_CONSTANTS.TITLES.QUALITY_ALL_MATERIALS, icon: 'lni lni-agenda', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: INTERNAL_ROUTES.KATIA_QUALITY_INSPECTION_ALL_MATERIALS_LIST, title: QUALITY_CONSTANTS.TITLES.QUALITY_INSPECTION_ALL_MATERIALS, icon: 'bx bx-list-check', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: INTERNAL_ROUTES.KATIA_QUALITY_LIBERACION_AGUA_LIST, title: QUALITY_CONSTANTS.TITLES.QUALITY_LIBERACION_AGUA, icon: 'bx bx-list-check', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '', title: PRODUCTION_CONSTANTS.TITLES.PRODUCTION, icon: 'bx bx-door-open', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: INTERNAL_ROUTES.KATIA_PRODUCTION_LIST_ALL_PRODUCT_SHEETS, title: PRODUCTION_CONSTANTS.TITLES.PRODUCTION_ALL_PRODUCT_SHEETS, icon: 'bx bx-food-menu', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: INTERNAL_ROUTES.KATIA_PRODUCTION_LIST_ALL_ORDER, title: PRODUCTION_CONSTANTS.TITLES.PRODUCTION_LIST_ALL_ORDER, icon: 'bx bx-food-menu', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '', title: BODEGA_CONSTANTS.TITLES.BODEGA, icon: 'bx bx-store-alt', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [

            { path: INTERNAL_ROUTES.KATIA_BODEGA_MATERIALES_LIST, title: BODEGA_CONSTANTS.TITLES.BODEGA_MATERIALES, icon: 'bx bx-extension', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: INTERNAL_ROUTES.KATIA_BODEGA_PRODUCTOS_LIST, title: BODEGA_CONSTANTS.TITLES.BODEGA_PRODUCTOS, icon: 'bx bx-package', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: INTERNAL_ROUTES.KATIA_BODEGA_EXISTENCIAS_MATERIALES_LIST, title: BODEGA_CONSTANTS.TITLES.BODEGA_EXISTENCIAS_MATERIALES, icon: 'bx bx-spreadsheet', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: INTERNAL_ROUTES.KATIA_BODEGA_EXISTENCIAS_PRODUCTOS_LIST, title: BODEGA_CONSTANTS.TITLES.BODEGA_EXISTENCIAS_PRODUCTO, icon:'bx bx-spreadsheet', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


        ]
    }
    ,
    {
        path: '', title: COMERCIAL_CONSTANTS.TITLES.COMERCIAL, icon: 'bx bx-world', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [

            { path: INTERNAL_ROUTES.KATIA_COMERCIAL_PROVEEDOR_INT_LIST, title: COMERCIAL_CONSTANTS.TITLES.PROVEEDOR_INT, icon: 'bx bx-customize', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: INTERNAL_ROUTES.KATIA_COMERCIAL_CLIENTE_INT_LIST, title: COMERCIAL_CONSTANTS.TITLES.CLIENTE_INT , icon: 'bx bx-crosshair', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


        ]
    },
    {
        path: '', title: EQUIPOS_CONSTANTS.TITLES.EQUIPOS, icon: 'bx bx-door-open', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: INTERNAL_ROUTES.KATIA_EQUIPOS_LIST, title: EQUIPOS_CONSTANTS.TITLES.EQUIPOS_NEW, icon: 'bx bx-food-menu', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: INTERNAL_ROUTES.KATIA_MANTENIMIENTO_EQUIPO_LIST, title: EQUIPOS_CONSTANTS.TITLES.MANTENIMIENTO_EQUIPO_LIST, icon: 'bx bx-food-menu', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '', title: PROGRAMACION_CONSTANTS.TITLES.PROGRAMACION, icon: 'bx bx-calendar', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: INTERNAL_ROUTES.KATIA_CRONOGRAMA_LIST, title: PROGRAMACION_CONSTANTS.TITLES.CRONOGRAMA_NEW, icon: 'bx bx-calendar', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: INTERNAL_ROUTES.KATIA_PLANEACION_LIST, title: PROGRAMACION_CONSTANTS.TITLES.PLANEACION_LIST, icon: 'bx bx-calendar', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            // { path: INTERNAL_ROUTES.KATIA_MANTENIMIENTO_EQUIPO_LIST, title: EQUIPOS_CONSTANTS.TITLES.MANTENIMIENTO_EQUIPO_LIST, icon: 'bx bx-food-menu', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },

    /*{
        path: '', title: MODULE_CONSTANTS.TITLES.MODULE, icon: 'bx bx-user', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: INTERNAL_ROUTES.MODULE_NEW, title: MODULE_CONSTANTS.TITLES.MODULE_NEW, icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: INTERNAL_ROUTES.MODULE_LIST, title: MODULE_CONSTANTS.TITLES.MODULE_LIST, icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '', title: ROLE_CONSTANTS.TITLES.ROLE, icon: 'bx bx-user', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: INTERNAL_ROUTES.ROLE_NEW, title: ROLE_CONSTANTS.TITLES.ROLE_NEW, icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: INTERNAL_ROUTES.ROLE_LIST, title: ROLE_CONSTANTS.TITLES.ROLE_LIST, icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },*/
];

/**
export const NAVIGATION_ROUTES: NavigationInfo[] = [

    {
        path: '', title: 'Dashboard', icon: 'bx bx-home-circle', class: 'sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
            { path: '/dashboard/e-commerce', title: 'eCommerce', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/dashboard/analytics', title: 'Analytics', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '', title: 'Application', icon: 'bx bx-category', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/application/email-app', title: 'Email', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/application/chat-box', title: 'Chat Box', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/application/file-manager', title: 'File Manager', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/application/contatcs', title: 'Contatcs', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/application/invoice', title: 'Invoice', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/application/calendar', title: 'Calendar', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    { path: '/widgets', title: 'Widgets', icon: 'bx bx-cookie', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
    {
        path: '', title: 'eCommerce', icon: 'bx bx-cart', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/ecommerce/products', title: 'Products', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/ecommerce/products-details', title: 'Products Details', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/ecommerce/add-new-products', title: 'Add New Products', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/ecommerce/orders', title: 'Orders', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '', title: 'Components', icon: 'bx bx-bookmark-heart', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/components/alerts', title: 'Alerts', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/components/badge', title: 'Badge', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/components/buttons', title: 'Buttons', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/components/cards', title: 'Cards', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/components/carousel', title: 'Carousel', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/components/acordians', title: 'Accordion', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/components/list-groups', title: 'List Groups', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/components/media-objects', title: 'Media Objects', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/components/modals', title: 'Modals', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/components/navs', title: 'Navs', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/components/navbar', title: 'Navbar', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/components/pagination', title: 'Pagination', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/components/progress-bar', title: 'Progress Bars', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/components/spinners', title: 'Spinners', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/components/avtars-chips', title: 'Avatrs & Chips', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '', title: 'ng-Components', icon: 'bx bx-layer icon-color-6', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/ng-components/accordion', title: 'Accordion', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/ng-components/alert', title: 'Alert', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/ng-components/buttons', title: 'Buttons', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/ng-components/carousel', title: 'Carousel', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/ng-components/collapse', title: 'Collapse', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/ng-components/datepicker', title: 'Datepicker', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/ng-components/dropdown', title: 'Dropdown', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/ng-components/modal', title: 'Modal', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/ng-components/nav', title: 'Nav', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/ng-components/pagination', title: 'Pagination', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/ng-components/popovers', title: 'Popovers', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/ng-components/progress-bar', title: 'Progressbar', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/ng-components/table', title: 'Table', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/ng-components/timepicker', title: 'Timepicker', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/ng-components/toast', title: 'Toast', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/ng-components/tooltip', title: 'Tooltip', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '', title: 'Content', icon: 'bx bx-repeat', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/content/grid-system', title: 'Grid System', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/content/typography', title: 'Typography', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/content/text-utilities', title: 'Text Utilities', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '', title: 'Icons', icon: 'bx bx-donate-blood', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/icons/line-icons', title: 'Line Icons', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/icons/boxicons', title: 'Boxicons', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '', title: 'Form', icon: 'bx bx-message-square-edit', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/form/form-elements', title: 'Form Elements', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/form/input-groups', title: 'Input Groups', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/form/form-layouts', title: 'Forms Layouts', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/form/form-validation', title: 'Form Validation', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/form/form-wizard', title: 'Form Wizard', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/form/text-editor', title: 'Text Editor', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/form/file-upload', title: 'File Upload', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/form/select2', title: 'Select2', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
           ]
    },
    {
        path: '/table/table', title: 'Table', icon: 'bx bx-grid-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '', title: 'Authentication', icon: 'bx bx-lock', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [

            { path: '/auth/sign-in', title: 'Sign In', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
            { path: '/auth/sign-up', title: 'Sign Up', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
            { path: '/auth/signin-with-header-footer', title: 'SignIn With Header Footer', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
            { path: '/auth/signup-with-header-footer', title: 'SignUp With Header Footer', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
            { path: '/auth/forgot-password', title: 'Forgot Password', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
            { path: '/auth/reset-password', title: 'Reset Password', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
            { path: '/auth/lock-screen', title: 'Lock Screen', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },

        ]
    },
    { path: '/user-profile', title: 'User Profile', icon: 'bx bx-user-circle', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
    { path: '/timeline', title: 'Timeline', icon: 'bx bx-video-recording', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
    {
        path: '', title: 'Errors', icon: 'bx bx-error', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/error/error-404', title: '404 Error', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
            { path: '/error/error-500', title: '500 Error', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
            { path: '/error/coming-soon', title: 'Coming Soon', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
           ]
    },
    { path: '/faq', title: 'FAQ', icon: 'bx bx-help-circle', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/pricing', title: 'Pricing', icon: 'bx bx-diamond', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/earnings', title: 'Earnings', icon: 'bx bx-dollar-circle', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/downloads', title: 'Downloads', icon: 'bx bx-download', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    {
        path: '', title: 'Charts', icon: 'bx bx-line-chart', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/charts/apex-chart', title: 'Apex Charts', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/charts/chartjs', title: 'ChartJs', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/charts/highcharts', title: 'Highcharts', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: '', title: 'Maps', icon: 'bx bx-map-alt', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/maps/google-maps', title: 'Google Maps', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/maps/fullscreen', title: 'Fullscreen Map', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    {
        path: 'javascript:;', title: 'Menu Levels', icon: 'bx bx-menu', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
            submenu: [
                { path: 'javascript:;', title: 'Level 1', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: 'javascript:;', title: 'Level 1', icon: 'bx bx-right-arrow-alt', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
                        submenu: [
                            { path: 'javascript:;', title: 'level 2', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                            { path: 'javascript:;', title: 'level 2', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

                        ] },
            ]
    },
    { path: 'https://codervent.com/syndron-angular/demo/vertical/docs/', title: 'Documentation', icon: 'bx bx-folder', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
    { path: 'https://themeforest.net/user/codewrrap/portfolio', title: 'Support', icon: 'bx bx-support', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] }


];
 */
