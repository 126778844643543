import { INTERNAL_ROUTES } from "@data/constants/routes";

export const ORDEN_COMPRA_CONSTANTS = {
    STATUS: {
        INICIAL_BORRADOR: 0,
        INICIAL: 1,
        MSG_INICIAL: 'INICIAL',
        SOLICITADO: 2,
        MSG_SOLICITADO: 'SOLICITADO',
        ACEPTADO: 3,
        MSG_ACEPTADO: 'ACEPTADO',
        OC_FINALIZADA: 4,
        MSG_OC_FINALIZADA: 'OC APROBADADA',
        CUARENTENA: 5,
        MSG_CUARENTENA: 'CUARENTENA',
        OC_CALIDAD: 6,
        MSG_OC_CALIDAD: 'OC CALIDAD',
        OC_RECHAZADO: 7,
        MSG_OC_RECHAZADO: 'RECHAZADO',
    },
    TITLES: {
        ORDENES_DE_COMPRA: 'Ordenes de Compra',
        ORDENES_COMPRA_NEW: 'Ingresar Orden Compra',
        ORDENES_COMPRA_LIST: 'Listado de Ordenes de Compra',
        ORDENES_COMPRA_EDIT: 'Editar Orden Compra',
        ORDENES_COMPRA_ENTER_ORDER: 'Ingresar Pedido',
        ORDENES_COMPRA_ORDER: 'Pedido',
        ORDENES_COMPRA_ENTER: 'Ingresar',
        ENTER_ORDER: 'Ingresar Pedido',
        MATERIALS: 'Materiales',
        MANAGE_ORDER_BUTTON: 'Gestionar Pedido',
        ENTER_MATERIAL_WAREHOUSE_BUTTON: 'Ingresar a Bodega',
        SEND_QUALITY_BUTTON: 'Enviar a Calidad',
    },
    LIST: {
        BUTTON_NEW: 'Ingresar Orden Compra',
        URL_ORDENES_COMPRA_NEW: INTERNAL_ROUTES.KATIA_ORDEN_COMPRA_NEW,
        SEARCH_ORDENES_COMPRA: 'Buscar Ordenes de Compra',
        SEARCH_STATUS_ORDENES_COMPRA: 'Buscar OCs Por Estado',
        MSG_CONFIRM_REQUEST_ORDER : '¿Esta Seguro de Solicitar el Pedido ##?',
        MSG_CONFIRM_ACCEPT_ORDER : '¿Esta Seguro de Aceptar el Pedido ##?',
        MSG_CONFIRM_FINISH_ORDER : '¿Esta Seguro de Emitir el Pedido ##?',
        MSG_CONFIRM_SEND_QUALITY_ORDER : '¿Esta Seguro de enviar a Calidad la Orden de compra: ##?',
        REQUEST_ORDER_TITLE: 'Solicitar Pedido',
        REQUEST_ORDER_MSG: 'Pedido Solicitado Exitosamente para la Orden de Compra ',
        ACCEPT_ORDER_TITLE: 'Aceptar Pedido',
        ACCEPT_ORDER_MSG: 'Pedido Aceptado Exitosamente para la Orden de Compra ',
        FINISH_ORDER_TITLE: 'Finalizar Pedido',
        FINISH_ORDER_MSG: 'Pedido Finalizado Exitosamente para la Orden de Compra ',
        DECLINE_ORDER_TITLE: 'Rechazo Pedido',
        DECLINE_ORDER_MSG: 'Pedido Rechazo Exitosamente para la Orden de Compra ',
        UPDATE_MATERIAL_ORDER_TITLE: 'Actualización Material',
        UPDATE_MATERIAL_ORDER_MSG: 'Material Actualizado Exitosamente para la Orden de Compra ',
        SEND_QUALITY_ORDER_TITLE: 'Enviar a Calidad',
        SEND_QUALITY_ORDER_MSG: 'Se envio a Calidad Exitosamente la Orden de Compra ',
        ORDER_CANNOT_BE_SENT_MATERIALS_WITHOUT_QUARANTINE: 'La Presente Orden de Compra, NO se puede enviar a Calidad. Existen materiales que aún no se encuentra en estado: CUARENTENA',
    },
    NEW: {
        BUTTON_BACK: 'Regresar',
        BUTTON_GUARDAR: 'Guardar',
        ORDEN_COMPRA: 'Orden de Compra',
        FECHA_PEDIDO: 'Fecha Pedido',
        PROVIDER: 'Proveedor',
        DESCRIPTION: 'Descripción',
        URL_ORDENES_COMPRA_LIST: INTERNAL_ROUTES.KATIA_ORDEN_COMPRA_LIST,
        MSG_REGISTER_ORDEN_COMPRA_TITLE: 'Crear Pedido',
        MSG_REGISTER_ORDEN_COMPRA_DESC: 'Se Registro Exitosamente el Pedido: ##1, para el proveedor: ##2',
    },
    ENTER_ORDER: {
        N_ORDEN_COMPRA: 'N° Orden Compra',
        FECHA_PEDIDO: 'Fecha Pedido',
        MSG_GET_PURCHASE_ORDER_TITLE: 'Información Orden Compra',
        MSG_GET_PURCHASE_ORDER_DESC: 'No se encontro información para la Orden de Compra seleccionada',
        ADD_MATERIAL:{
            TITULO_MATERIAL:"Material",
            CANTIDAD_MATERIAL: "Cantidad",
            CANTIDAD_A_SOLICITAR: 'Cantidad a Solicitar',
            FECHA_ENTREGA_MATERIAL: "Fecha Entrega",
            ADD_MATERIAL_BUTTON: "Adicionar Material",
            MSG_ADD_MATERIAL_PURCHASE_ORDER_TITLE: 'Ingresar Material - Orden Compra ',
            MSG_ADD_MATERIAL_PURCHASE_ORDER_DESC: 'Se Registro Exitosamente el material ',
        },
        STATUS_MATERIAL: {
            TRANSIT: 1,
            MSG_TRANSIT: 'En transito',
            QUARENTINE: 2,
            MSG_QUARENTINE: 'Cuarentena',
            TO_CHECK: 3,
            MSG_TO_CHECK: 'Por Revisar',
            APPROVE: 4,
            MSG_APPROVE: 'Aprobado',
            DECLINE: 5,
            MSG_DECLINE: 'Rechazado',
            INSPECTION: 6,
            MSG_INSPECTION: 'Inspección',
            MSG_INSPECTION_MEE: 'Por Inspeccionar',
            MSG_INSPECTION_MEE_OK: 'Inspección Realizada',
            MSG_INSPECTION_ANALYSIS_MP: 'Por Cert. Análisis',
            MSG_INSPECTION_MP_OK: 'Análisis Realizado',
            INSPECTION_REJECTED: 7,
            MSG_INSPECTION_REJECTED: 'Inspección Rechazada',
        },
    },
    QUARANTINE: {
        AMOUNT: 'Cantidad',
        REQUESTED_AMOUNT: 'Cantidad Solicitada',
        RECEIVED_AMOUNT: 'Cantidad Recibida',
        LOTE_PROVIDER: 'Lote Proveedor',
        GROSSS_WEIGHT: 'Peso Bruto',
        NET_WEIGHT: 'Peso Neto',
        UNIT_VALUE: 'Valor Unitario',
        FULL_VALUE: 'Valor Total',
        DELIVER_DATE: 'Fecha Entrega',
        EXPIRATION_DATE: 'Fecha Vencimiento',
        OBSERVATIONS: 'Observaciones',
        INITIAL_DEADLINE: 'Fecha Entrega Propuesta',
        MSG_GET_QUARANTINE_TITLE: 'Material Orden Compra',
        MSG_GET_QUARANTINE_DESC: 'No se encontro información para el material del pedido seleccionado',
        MSG_EDIT_MATERIAL_TITLE: 'Actualizar Material',
        MSG_EDIT_MATERIAL_DESC: 'Se Actualizo Exitosamente el Material ##1 de la orden de compra ##2',
        BUTTON_ENTER_MATERIAL: 'Guardar',
        BUTTON_EDIT_MATERIAL: 'Editar',
        BUTTON_FINALIZE_MATERIAL: 'Finalizar',
        BUTTON_BACK: 'Regresar',
    }
}
