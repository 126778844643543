import { INTERNAL_ROUTES } from "@data/constants/routes";

export const BODEGA_CONSTANTS = {
    TITLES: {
        BODEGA: 'Bodega',
        BODEGA_MATERIALES: 'Bodega de materiales',
        BODEGA_EXISTENCIAS_MATERIALES: 'Existencias bodega material',
        BODEGA_EXISTENCIAS_PRODUCTO: 'Existencias bodega productos',
        BODEGA_MATERIALES_NEW: 'Agregar material',
        BODEGA_MATERIALES_MATERIA_PRIMA: 'Materia prima',
        BODEGA_MATERIALES_ENVASE_ETIQUETA: 'Envase y etiqueta',
        BODEGA_MATERIALES_INSUMOS: 'Insumos',
        BODEGA_PRODUCTOS: 'Bodega de productos',
        BODEGA_PRODUCTOS_NEW: 'Agregar producto',
        BODEGA_PRODUCTOS_TERMINADOS: 'Productos terminados',
        BODEGA_PRODUCTOS_SEMITERMINADOS: 'Productos semi-terminados'
        
    },
    LIST: {
        BUTTON_NEW: 'Ingresar Producto',        
        URL_MATERIALES_NEW: INTERNAL_ROUTES.KATIA_BODEGA_MATERIALES_NEW,  
        URL_PRODUCTOS_NEW: INTERNAL_ROUTES.KATIA_BODEGA_PRODUCTOS_NEW,       
        SEARCH_PRODUCTS: 'Buscar Productos',
        SEARCH_MATERIALS: 'Buscar Material',
        SEARCH_PRODUCTOS: 'Buscar Productos',
    },
    STATUS: {
        ACTIVE: 1,   
        MSG_ACTIVE: 'Activo',       
        INACTIVE: 2, 
        MSG_INACTIVE: 'Inactivo',
    },
    NEW: {
        BUTTON_BACK: 'Regresar',
        BUTTON_GUARDAR: 'Guardar',
        URL_PRODUCT_LIST: INTERNAL_ROUTES.KATIA_PRODUCTION_LIST_ALL_PRODUCT_SHEETS,
        MSG_REGISTER_PRODUCT_TITLE: 'Crear Producto',
        MSG_REGISTER_PRODUCT_DESC: 'Se Registro Exitosamente el Producto: ##1',
    }
}