import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Inject } from '@angular/core';
import { MaterialService } from '@data/services/api/material.service';
import { finalize } from 'rxjs/operators';


@Component({
  selector: 'app-view-materials-modal',
  templateUrl: './view-materials-modal.component.html',
  styleUrls: ['./view-materials-modal.component.scss']
})
export class ViewMaterialsModalComponent{

  displayedColumns: string[] = ['nomGenerico'];
  public dataSource: MatTableDataSource<any>;
  dataMateriales
  public proveedorId: string; 

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private materialService: MaterialService) {
    this.proveedorId = data.proveedorId || "";
  }

  ngOnInit(): void {
    this.getAllMaterialesAsociados();

  }

  getAllMaterialesAsociados() {

    //let spinnerRef = this.spinnerService.start();
    this.materialService.getAllMaterialesProveedor(this.proveedorId).pipe(finalize(() => {
      //this.spinnerService.stop(spinnerRef);
    })).subscribe(r => {

      this.dataMateriales = r.data;

      if (this.dataMateriales && this.dataMateriales.length > 0) {
        this.dataSource = new MatTableDataSource(this.dataMateriales);
      }

    });
  }

}
